<template>
  <div>
    <el-breadcrumb class="breadcrumb-title"
      separator="/">
      <el-breadcrumb-item>组织管理</el-breadcrumb-item>
      <el-breadcrumb-item>外部联系人</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="title">
        外部联系人
        <i class="iconfont iconyuangongguanli icon_gongguanli"
          style="margin-left: 5px; color: #d8d8d8"></i>
        <span> {{ checkedDeptMemberCount }}</span>
      </div>
      <div class="flex-row flex-row-space-between mb20">
        <div class="flex-row ml20">
          <SearchInput class="search-input"
            placeholderStr="请输入姓名或手机号"
            @updateSearchList="updateSearchList" />
          <div class="pointer ml10 blue"
            @click="onClickRoleExplain">
            角色权限说明
            <i class="el-icon-info"></i>
          </div>
        </div>
        <div class="search-right">
          <el-button type="primary"
            style="width: 9rem"
            class="addBtn"
            @click="onOpenAddExternalDialog">添加外部联系人</el-button>
          <el-button type="primary"
            class="addBtn"
            @click="toImport">批量导入</el-button>
        </div>
      </div>
      <ExternalTable :TabData="tableData"
        :SearchDepartmentList="SearchDepartmentList"
        @sureEdit="sureEdit"
        @toShipSureUp="toShipSureUp"
        @delEditSure="delEditSure"
        :Visible="Visible"
        @delExternalContacts="delExternalContacts"
        :add="add"
        ref="refExternalChild"></ExternalTable>
      <el-pagination @current-change="handleCurrentChange"
        :current-page="curPage"
        :page-size="pageSize"
        :page-sizes="[pageSize]"
        layout="prev, pager, next, sizes, jumper , total"
        background
        :total="pageData.length">
      </el-pagination>
    </el-card>
    <RoleExcplainDialog ref="refRoleExcplainDialog"></RoleExcplainDialog>
    <AddExternalContactsDialog ref="refAddExternalContactsDialog"
      @onSaveSubmit="onAddSaveSubmit"></AddExternalContactsDialog>
  </div>
</template>
<script>
import request from "@request/api/index";
import SearchInput from "@components/SearchInput";
import RoleExcplainDialog from "@components/RoleExplainDialog.vue";
import ExternalTable from "./components/ExternalTable";
import AddExternalContactsDialog from "./components/AddExternalContactsDialog";
import memberMix from "../membersDepartments/components/mixins/memberMix";

export default {
  mixins: [memberMix],
  components: {
    SearchInput,
    ExternalTable,
    RoleExcplainDialog,
    AddExternalContactsDialog,
  },
  data() {
    return {
      checkedDeptMemberCount: 0,
      restaurants: [],
      pageData: [],
      searchDate: [],
      state: "",
      timeout: null,
      curPage: 1,
      pageSize: 10,
      searchMes: {
        eventTitle: "",
      },
      Visible: false,
      add: false,
      tableData: [],
      SearchDepartmentList: [],
      addVisible: false,
    };
  },
  watch: {
    pageData() {
      let page = this.pageData.length / this.pageSize + 1;
      if (this.pageData.length % this.pageSize === 0) {
        page--;
      }
      if (this.curPage > 1 && this.curPage > page) {
        this.curPage = page;
      }
      this.computeList();
    },
  },
  created() {
    this.getDate();
    this.getSearchDepartment();
  },
  methods: {
    onClickRoleExplain() {
      this.$refs.refRoleExcplainDialog.open();
    },
    onOpenAddExternalDialog() {
      this.$refs.refAddExternalContactsDialog.open();
    },
    computeList() {
      const start = (this.curPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.tableData = this.pageData.slice(start, end);
    },
    getDate() {
      request.organization.getExternalContactsList({}).then((res) => {
        this.tableData = res.data.data.list;
        this.searchDate = res.data.data.list;
        this.pageData = res.data.data.list;
        this.checkedDeptMemberCount = res.data.data.count;
      });
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.computeList();
    },
    filterData() {
      const copyList = JSON.parse(JSON.stringify(this.searchDate));
      const arr = [];
      for (let i = 0; i < copyList.length; i++) {
        if (this.searchInputText !== "") {
          if (
            copyList[i].nickname.indexOf(this.searchInputText) >= 0 ||
            copyList[i].phone.indexOf(this.searchInputText) >= 0
          ) {
            arr.push(copyList[i]);
          }
        } else {
          this.pageData = this.searchDate;
          return false;
        }
      }
      this.pageData = arr;
    },
    updateSearchList(str) {
      this.searchInputText = str;
      this.filterData();
    },
    // 添加外部联系人
    async onAddSaveSubmit(item) {
      try {
        let params = {
          id: item.id,
          company_name: item.company_name,
          position: item.position,
          name: item.name,
          nickname: item.nickname,
          email: item.email,
          phone: item.phone,
          user_id: item.user_id,
        };
        if (params.email === "") {
          delete params.email;
        }
        this.formatSaveMediaAuth(params, item);
        const addData = await request.organization.requestAddExternalContacts(
          params
        );
        if (addData.data.code === 1000) {
          this.$message.success("添加成功！");
          this.getDate();
          this.$refs.refAddExternalContactsDialog.close();
        } else {
          this.addVisible = true;
        }
      } catch (err) {
        console.log(err)
      }
    },
    async sureEdit(editForm) {
      let params = {
        id: editForm.id,
        company_name: editForm.company_name,
        position: editForm.position,
        name: editForm.name,
        nickname: editForm.nickname,
        email: editForm.email,
        phone: editForm.phone,
        department_id: editForm.department_id,
        user_id: editForm.user_id,
      };
      if (params.email === "") {
        delete params.email;
      }
      this.formatSaveMediaAuth(params, editForm);
      await request.organization
        .requestEditExternalContacts(params)
        .then((res) => {
          const { code } = res.data;
          if (code === 1000) {
            this.$message.success("编辑成功！");
            this.$refs.refExternalChild.hideDialog();
            this.getDate();
          }
        });
    },
    delEditSure(params) {
      this.getStatusMember(params);
    },
    // 编辑成员状态
    getStatusMember(params) {
      request.organization.requestStatus(params).then((res) => {
        this.getDate();
      });
    },
    // 删除
    delExternalContacts(params) {
      this.getdelExternalContacts(params);
    },
    getdelExternalContacts(params) {
      request.organization.requestDelExternalContacts(params).then((res) => {
        this.getDate();
      });
    },
    toShipSureUp(params) {
      this.getMembership(params);
    },
    // 转为成员
    getMembership(params) {
      request.organization.requestMembership(params).then((res) => {
        if (res.data.code === 1000) {
          this.$message.success("已转成功！");
          this.getDate();
          this.$refs.refExternalChild.hideDialog();
        }
      });
    },
    // 部门搜索
    getSearchDepartment() {
      request.organization.requestSearchDepartment({}).then((res) => {
        this.SearchDepartmentList = res.data.data.list;
      });
    },
    toImport() {
      this.$router.push({
        path: "/importPage",
        query: {
          type: 2,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-card {
  border-radius: 8px;
  min-height: 900px;
  position: relative;
}
.title {
  margin-bottom: 20px;
  margin-left: 33px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.el-input {
  width: 188px !important;
  margin-bottom: 40px;
}
</style>
