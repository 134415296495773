<template>
  <div>
    <el-table :data="TabData"
      style="width: 100%"
      :header-cell-style="{ background: '#F5F7FA', color: '#606266' }">
      <el-table-column prop="nickname"
        label="姓名"
        width="180">
      </el-table-column>
      <el-table-column prop="company_name"
        label="公司"
        width="180">
      </el-table-column>
      <el-table-column prop="position"
        label="职位"> </el-table-column>
      <el-table-column prop="phone"
        label="手机号"> </el-table-column>
      <el-table-column prop="email"
        label="邮箱"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-button type="text"
            style="color: #3468fe"
            v-if="scope.row.status == 1"
            size="small">
            已启用
          </el-button>
          <el-button type="text"
            v-if="scope.row.status == 0"
            style="color: #f56c6c"
            size="small">
            已停用
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="rowClick(scope.row)"
            type="text"
            size="small "
            class="blue">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 右侧窗 -->
    <el-drawer :visible.sync="Formdialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer">
      <div class="demo-drawer__content">
        <el-form :model="editForm"
          :rules="rules"
          ref="rightExternalForm">
          <el-form-item label="姓名:"
            prop="name"
            :label-width="formLabelWidthRight">
            <el-input :disabled="true"
              class="search-input-dialog"
              type="text"
              placeholder="请输入姓名"
              v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号:"
            prop="phone"
            :label-width="formLabelWidthRight">
            <el-input :disabled="true"
              class="search-input-dialog"
              type="text"
              placeholder="请输入手机号"
              v-model="editForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:"
            prop="email"
            :label-width="formLabelWidthRight">
            <el-input :disabled="true"
              class="search-input-dialog"
              type="text"
              placeholder="请输入邮箱"
              v-model="editForm.email"></el-input>
          </el-form-item>
          <el-form-item label="公司:"
            prop="company_name"
            :label-width="formLabelWidthRight">
            <el-input class="search-input-dialog"
              type="text"
              placeholder="请输入公司"
              v-model="editForm.company_name"></el-input>
          </el-form-item>
          <el-form-item label="职位:"
            prop="position"
            :label-width="formLabelWidthRight">
            <el-input class="search-input-dialog"
              type="text"
              placeholder="请输入职位"
              v-model="editForm.position"></el-input>
          </el-form-item>
          <el-form-item label="媒资库权限:"
            :label-width="formLabelWidthRight">
            <el-cascader v-model=editForm.mediaAuth
              :options="mediaAuthOps"
              class="input-max-width"
              :props="{ multiple: true }"
              clearable></el-cascader>
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer"
          style="text-align: center; margin-top: 50px">
          <el-button type="primary"
            class="buttonColor"
            @click="sureEditMember">保存</el-button>
          <el-button v-if="editForm.status == 1"
            @click="changeStatus">停用</el-button>
          <el-button type="primary"
            v-if="editForm.status == 0"
            @click="changeStatus">启用</el-button>
          <el-button type="primary"
            class="addBtn"
            @click="toMembership">转为成员</el-button>
          <el-button type="danger"
            @click="delMember">删除</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog width="40%"
      :title="titleList"
      :visible.sync="delFormVisible">
      <span v-if="titleList == '删除'"
        class="del-content">确认删除该账号吗，删除后将无法找回</span>
      <span v-if="titleList == '启用'"
        class="del-content">确认启用该账号吗，启用后该账号将恢复使用</span>
      <span v-if="titleList == '停用'"
        class="del-content">确认停用该账号吗，停用后该账号将暂停使用。</span>
      <div slot="footer"
        class="dialog-footer">
        <el-button @click="delFormVisible = false">取 消</el-button>
        <el-button type="primary"
          class="buttonColor"
          @click="delSure">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 转成会员 弹框 -->
    <el-dialog width="40%"
      title="转为成员"
      :visible.sync="MembershipFormVisible">
      <el-form :model="MembershipForm"
        ref="MembershipForm">
        <el-form-item>
          <div class="line">
            转为成员后，该账号将获得企业成员相关权限，建议谨慎使用。
          </div>
        </el-form-item>
        <el-form-item label="部门: "
          prop="top_name"
          :label-width="formLabelWidth"
          :rules="[
            {
              required: true,
              message: '请输入选择部门',
              trigger: 'blur',
            },
          ]">
          <el-input v-model="MembershipForm.top_name"
            name="top_name"
            @focus="showModalTree = true"
            clearable
            placeholder="请选择组织"
            style="width: 300px !important"
            autocomplete="off"></el-input>
          <transition name="el-zoom-in-top">
            <div class="modalTree"
              v-if="showModalTree">
              <el-tree ref="tree"
                :data="SearchDepartmentList"
                default-expand-all
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
                style="left: 60px; width: 57%; z-index: 90"></el-tree>
            </div>
          </transition>
          <div class="treeModal"
            @click="showModalTree = false"
            v-if="showModalTree"></div>
        </el-form-item>
        <el-form-item label="职位: "
          prop="position"
          :label-width="formLabelWidth"
          :rules="[
            {
              required: true,
              message: '请输入职位',
              trigger: 'blur',
            },
            {
              min: 2,
              max: 20,
              message: '长度在 2 到 20个字符',
              trigger: 'blur',
            },
          ]">
          <el-input class="search-input-dialog"
            type="text"
            placeholder="请输入职位"
            maxlength="10"
            v-model="MembershipForm.position"
            autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
        class="dialog-footer">
        <el-button @click="MembershipFormVisible = false">取 消</el-button>
        <el-button type="primary"
          class="buttonColor"
          @click="MembershipSure('MembershipForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import memberMix from "../../membersDepartments/components/mixins/memberMix.js";

export default {
  mixins: [memberMix],
  props: {
    SearchDepartmentList: Array,
    add: Boolean,
    addVisible: Boolean,
    TabData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      curPage: 1,
      pageSize: 10,
      addFormVisible: false,
      addForm: {},
      formLabelWidth: "120px",
      formLabelWidthRight: "80px",
      editForm: { name: "" },
      delFormVisible: false,
      MembershipFormVisible: false,
      MembershipForm: {},
      titleList: "",
      Formdialog: false,
      showModalTree: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入电话号码",
            trigger: "blur",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        company_name: [
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        top_name: [
          {
            required: true,
            message: "请选择组织",
            trigger: "blur",
          },
        ],
        position: [
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    MembershipFormVisible(newVal, old) {
      if (old) {
        this.MembershipForm = {};
      }
    },
  },
  methods: {
    addBtn() {
      this.addFormVisible = true;
      this.addForm = {};
    },

    hideDialog() {
      this.addFormVisible = false;
      this.Formdialog = false;
      this.MembershipFormVisible = false;
    },
    rowClick(row) {
      this.Formdialog = true;
      row.mediaAuth = this.formatShowMediaAuth(row.media);
      this.editForm = { ...row, name: row.nickname };
    },
    //    保存
    sureEditMember() {
      this.$refs.rightExternalForm.validate((valid) => {
        if (valid) {
          this.$emit("sureEdit", this.editForm);
        }
      });
    },
    // 停用，启用
    changeStatus() {
      if (this.editForm.status === 0) {
        this.titleList = "启用";
      } else if (this.editForm.status === 1) {
        this.titleList = "停用";
      }
      this.delFormVisible = true;
    },
    // 删除
    delMember() {
      this.titleList = "删除";
      this.delFormVisible = true;
    },
    delSure() {
      const userId = this.editForm.user_id;
      if (this.titleList === "删除") {
        // this.getDelMember();
        const delmemberId = {
          user_id: userId,
        };
        this.$emit("delExternalContacts", delmemberId);
      } else if (this.titleList === "启用" || this.titleList === "停用") {
        const status = this.editForm.status === 1 ? 0 : 1;
        const params = {
          status,
          user_id: userId,
        };

        this.$emit("delEditSure", params);
      }
      this.delFormVisible = false;
      this.Formdialog = false;
    },
    toMembership() {
      this.MembershipFormVisible = true;
      console.log(this.MembershipForm);
      this.MembershipForm.top_name = this.SearchDepartmentList[0].label;
      this.MembershipForm.department_id = this.SearchDepartmentList[0].id;
      // this.MembershipForm.position = '';
    },
    MembershipSure(formName) {
      // 通知父发送转移post
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            user_id: this.editForm.user_id,
            department_id: this.MembershipForm.department_id,
            position: this.MembershipForm.position,
          };
          this.$emit("toShipSureUp", params);
        }
      });
    },
    handleNodeClick(node) {
      this.MembershipForm.department_id = node.id;
      this.MembershipForm.top_name = node.label;
      this.showModalTree = false;
      this.$refs.MembershipForm.clearValidate();
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.line {
  width: 96%;
  margin: 0 auto;
  border: 3px solid #3468fe;
  line-height: 2rem;
  background: #b4d3f1;
  padding-left: 20px;
  margin-bottom: 20px;
}
.search-input-dialog {
  width: 370px !important;
}
::v-deep .el-button--text {
  color: $blue;
}
</style>

