export default {
  //       look_permission          查看权限: 0-无 1-有
  // invite_permission        邀请权限: 0-无 1-有
  // download_permission      下载权限: 0-无 1-有
  // share_permission         分享权限: 0-无 1-有
  // copy_permission          复制权限: 0-无 1-有
  // delete_permission        删除权限: 0-无 1-有
  //tag_permission    标签权限  0 无 1 有
  //up_permission    上传权限  0 无 1 有
  //move_permission   移动权限  0 无 1 有
  data() {
    return {
      mediaAuthOps: [
        {
          value: 'look_permission',
          label: '查看',
        },
        {
          value: 'up_permission',
          label: '上传编辑',
        },
        {
          value: 'download_permission',
          label: '下载',
        },
        {
          value: 'copy_permission',
          label: '复制',
        },
        {
          value: 'move_permission',
          label: '移动',
        },
        {
          value: 'share_permission',
          label: '分享',
        },
        {
          value: 'tag_permission',
          label: '标签管理',
        },
        {
          value: 'delete_permission',
          label: '删除',
        },
      ],
    };
  },
  methods: {
    defaultSelected(newItem) {
      // 默认赋值的时候得按照这个组件的值的顺序来，即value按照options的顺序来，即下面代码，就可以正确删除啦。
      newItem.mediaAuth = [
        ['look_permission'],
        ['up_permission'],
        ['download_permission'],
        ['copy_permission'],
        ['move_permission'],
        ['share_permission'],
        ['tag_permission'],
        ['delete_permission'],
      ];
    },
    formatShowMediaAuth(olauth) {
      let mediaAuth = [];
      if (olauth.look_permission == 1) {
        mediaAuth.push(['look_permission']);
      }
      if (olauth.up_permission == 1) {
        mediaAuth.push(['up_permission']);
      }
      // if (olauth.invite_permission == 1) {
      //   mediaAuth.push(['invite_permission']);
      // }
      if (olauth.download_permission == 1) {
        mediaAuth.push(['download_permission']);
      }
      if (olauth.copy_permission == 1) {
        mediaAuth.push(['copy_permission']);
      }
      if (olauth.share_permission == 1) {
        mediaAuth.push(['share_permission']);
      }
      if (olauth.move_permission == 1) {
        mediaAuth.push(['move_permission']);
      }
      if (olauth.tag_permission == 1) {
        mediaAuth.push(['tag_permission']);
      }
      if (olauth.delete_permission == 1) {
        mediaAuth.push(['delete_permission']);
      }
      return mediaAuth;
    },
    formatSaveMediaAuth(param, item) {
      let saveMediaAuth = [];
      let mediaAuth = item.mediaAuth || [];
      mediaAuth.forEach((element) => {
        saveMediaAuth.push(element[element.length - 1]);
      });
      console.log(item);
      param.look_permission = 0;
      // param.invite_permission = 0;
      param.download_permission = 0;
      param.share_permission = 0;
      param.copy_permission = 0;
      param.delete_permission = 0;
      param.move_permission = 0;
      param.tag_permission = 0;
      param.up_permission = 0;

      if (saveMediaAuth.length > 0) {
        for (let i = saveMediaAuth.length - 1; i >= 0; i--) {
          if (saveMediaAuth[i]) {
            param[saveMediaAuth[i]] = 1;
          }
        }
        //如果勾选其他权限，查看权限默认选中
        param.look_permission = 1;
      }
    },
  },
};
