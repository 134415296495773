<template>
  <div>
    <!-- 添加外部联系人 -->
    <el-dialog width="40%"
      title="添加外部联系人"
      :visible.sync="isShow"
      :before-close="close">
      <el-form ref="addExternalUserForm"
        :rules="rules"
        :model="addForm">
        <el-form-item label="姓名:"
          prop="name"
          :label-width="formLabelWidth">
          <el-input class="search-input-dialog"
            type="text"
            placeholder="请输入姓名"
            @input="change"
            v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号:"
          prop="phone"
          :label-width="formLabelWidth">
          <el-input class="search-input-dialog"
            type="text"
            placeholder="请输入手机号"
            @input="change"
            v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:"
          prop="email"
          :label-width="formLabelWidth">
          <el-input class="search-input-dialog"
            type="text"
            placeholder="请输入邮箱"
            @input="change"
            v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="公司:"
          prop="company_name"
          :label-width="formLabelWidth">
          <el-input class="search-input-dialog"
            type="text"
            placeholder="请输入公司名称"
            @input="change"
            v-model="addForm.company_name"></el-input>
        </el-form-item>
        <el-form-item label="职位:"
          prop="position"
          :label-width="formLabelWidth">
          <el-input class="search-input-dialog"
            type="text"
            placeholder="请输入职位"
            @input="change"
            v-model="addForm.position"></el-input>
        </el-form-item>
        <el-form-item label="媒资库权限:"
          :label-width="formLabelWidth">
          <el-cascader v-model=addForm.mediaAuth
            :options="mediaAuthOps"
            class="input-max-width"
            :props="{ multiple: true }"
            clearable></el-cascader>
        </el-form-item>

      </el-form>
      <div slot="footer"
        class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary"
          class="buttonColor"
          @click.stop="onSaveSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import memberMix from "../../membersDepartments/components/mixins/memberMix.js";
export default {
  name: "AddExternalContactsDialog",
  mixins: [memberMix],
  data() {
    return {
      addForm: {
        mediaAuth: [],
        name: "",
        phone: "",
        email: "",
        company_name: "",
        top_name: "",
        position: "",
      },
      isShow: false,
      formLabelWidth: "120px",
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "change",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入电话号码",
            trigger: "change",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        company_name: [
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        top_name: [
          {
            required: true,
            message: "请选择组织",
            trigger: "blur",
          },
        ],
        position: [
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.showModalTree = false;
      this.addForm = {
        mediaAuth: [],
        name: "",
        phone: "",
        email: "",
        company_name: "",
        top_name: "",
        position: "",
      };
      this.$refs.addExternalUserForm.resetFields();
      this.isShow = false;
    },
    open() {
      this.isShow = true;
    },
    change() {
      this.$forceUpdate();
    },
    onSaveSubmit() {
      this.$refs.addExternalUserForm.validate((valid) => {
        if (valid) {
          this.$emit("onSaveSubmit", this.addForm);
        }
      });
    },
  },
};
</script>